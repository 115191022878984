html {
    width: 100%;
    height: 100%;
}

body {
    /* width: 100vw; */
    scroll-snap-type: y mandatory;
}

@media (max-width: 960px) {
    body, html {
        font-size: 14px !important;
    }
}

header {
    background: transparent !important;
    box-shadow: none !important;
}

div#__next {
    height: 100%;
}

::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: #e5e5e580;
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    background: #dadada;
}

.MuiPaper-root {
    overflow: visible !important;
}



/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #82CD1C;

    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    width: 100%;
    height: 4px;
    opacity: 0.7;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #82CD1C, 0 0 5px #82CD1C;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner-icon {
    display: none;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}
